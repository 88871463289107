.project-row {
  width: 100%;
  box-shadow: 0px 8px 0px $lightblue;
  border: 1px solid $lightblue;
  border-radius: 33px;
  transition: 0.2s ease-out;
  position: relative;
  top: 0;
  &:hover {
    box-shadow: 0px 20px 20px rgba(3, 78, 151, 0.15);
    top: -10px;
  }
}

.project-thumbnail {
  @extend %img-shadow;
  width: 100%;
  padding-top: 100%;
  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
  }
}

.project-info {
  @include media-breakpoint-up(md) {
    padding-top: 1rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 4rem;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
  }
}

.project-thumbnail-home {
  @include media-breakpoint-down(md) {
    @include img-shadow-right(18px, -18px, 100%);
    margin-bottom: 50px;
  }
  @include img-shadow-right(30px, -30px, 100%);
}

.project-title {
  color: $primary;
}
