.cookies-greeting {
  font-size: 2rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #dedede;
}

.cookies-scrollable {
  a {
    word-break: break-all;
  }
  max-height: 260px;
  @include media-breakpoint-down(xs) {
    max-height: 130px;
  }
  overflow-y: auto;
  h2 {
    font-weight: 700;
    margin: 1.4rem 0;
  }
  h3 {
    font-weight: 700;
    margin: 1rem 0;
  }
  scrollbar-color: $primary rgb(236, 236, 236);
}

.cookies-modalfooter {
  border-top: 1px solid #dedede;
  margin-top: 1rem;
  padding-top: 1rem;
  @include media-breakpoint-down(xs) {
    margin-top: 5px;
    padding-top: 10px;
  }
}

.modal-body p {
  @include media-breakpoint-down(xs) {
    margin-bottom: 5px;
  }
}
.cookies-box {
  font-size: 16px;
  font-family: $font-family-sans-serif;
  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }
}

.cookies-scrollable h3 {
  font-weight: 500;
  @include media-breakpoint-down(xs) {
    margin: 10px 0 10px 0;
  }
}

.cookies-scrollable::-webkit-scrollbar-track {
  margin-top: 20px;
  border-radius: 3px;
  background: rgb(236, 236, 236);
}

.cookies-scrollable::-webkit-scrollbar {
  width: 6px;
}

.cookies-scrollable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 6px;
  background: $primary;
}

.cookies-scrollable::-webkit-scrollbar-track-piece {
  height: 30px;
  width: 30px;
}

.cookies-title {
  color: $primary;
  font-weight: 500;
  font-size: 20px;
  @include media-breakpoint-down(xs) {
    font-size: 16px;
  }
}

.cookies-txt {
  color: $secondary;
  font-weight: 300;
}

.cookies-accept-btn {
  color: white;
  background: $primary;
  border: none;
}

.cookies-decline-btn {
  border: 1px solid $primary;
  background: none;
}

.cookies-accept-btn,
.cookies-decline-btn {
  min-width: 330px;
  @include media-breakpoint-down(md) {
    min-width: 200px;
  }
  @include media-breakpoint-down(xs) {
    min-width: 120px;
  }
}

.modal-title {
  @include media-breakpoint-down(xs) {
    font-size: 20px;
  }
}

.modal-header {
  border: none;
  padding: 35px 30px 15px 30px;
  @include media-breakpoint-down(xs) {
    padding: 20px 15px 5px 15px;
  }
}

.modal-body {
  padding: 0px 30px 0px 30px;
  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.modal-content {
  border-radius: 20px;
  box-shadow: 0px 20px 20px rgba(3, 78, 151, 0.15);
}

.modal-footer {
  border: none;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
