.cooperation .main-header {
  margin-bottom: 50px;
}

.partnership-contact-button {
  margin-top: 40px;
  text-align: right;
}

.partnership ul {
  padding: 0;
}

.partnership ul > li {
  list-style-type: none;
  margin: 20px 0 20px 0;
}

.partnership ul > li:before {
  content: '\2022';
  font-size: 60px;
  padding-right: 20px;
  vertical-align: middle;
  line-height: 5px;
  color: $primary;
}
