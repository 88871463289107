.EpubView-page-height {
  height: 90vh;
  @include media-breakpoint-down(sm) {
    height: 100vh;
  }
}

.EpubView-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;

  opacity: 1;
  transition: all 0.5s ease-in;

  .EpubView-epub {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    &.toc-hidden {
      width: 100%;
    }
  }

  .EpubView-toc {
    z-index: 999;
    height: 100%;
    flex: 0 0 300px;
    opacity: 1;
    transition: all 0.3s ease-in-out;

    &.toc-hidden {
      transition: all 0.3s ease-in-out;
      flex: 0 0 0;
      opacity: 0;
    }
  }
}

.EpubView-epub-top {
  flex: 0 0 25px;
}
.EpubView-viewer-container {
  height: calc(100% - 81px);
}
.EpubView-toc-btn {
  width: 116px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding-right: 0;
  padding-left: 0;
  display: flex;
  align-content: space-evenly;
  margin-top: 10px;
  > span {
    margin-left: 10px;
    color: #034e97;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.29px;
    line-height: 21px;
  }
}

.EpubView-viewer {
  width: 100%;
  height: 100%;
}
.EpubView-btn-group {
  flex: 0 0 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  justify-items: center;
  flex-direction: row;
  > span {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    height: 22px;
    color: #1d2636;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
  }
}

.EpubView-prev {
  display: flex;
  align-content: center;
  justify-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 30px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #034e97;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  @include media-breakpoint-down(md) {
    margin-right: 10px;
  }

  > svg {
    margin-right: 9px;
  }
}
.EpubView-pagination-input {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 60px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid rgba(29, 38, 54, 0.6);
  background-color: #ffffff;
  color: rgba(29, 38, 54, 0.6);
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  @include media-breakpoint-down(md) {
    width: 45px;
  }
}

.EpubInput-wrapper {
  position: relative;
  margin-right: 10px;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.EpubInput-loader {
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 4px;
  .EpubInput-loader-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: $primary;
    margin-right: 2px;
  }
  span {
    animation: epub-loader-animation 1s infinite;
  }
  span:nth-child(2) {
    animation-delay: 0.1s;
  }
  span:nth-child(3) {
    animation-delay: 0.2s;
  }
  @keyframes epub-loader-animation {
    0%,
    60%,
    100% {
      transform: initial;
    }
    30% {
      transform: translateY(-4px);
    }
  }
}
.EpubView-next {
  margin-left: 30px;
  display: flex;
  align-content: center;
  justify-items: center;
  justify-content: center;

  margin-top: auto;
  margin-bottom: auto;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #034e97;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  > svg {
    margin-left: 9px;
  }
}

.epub-view-loader-wrapper {
  position: absolute;
  background: white;
  z-index: 9999;
  width: 100%;
  height: 100%;
  .epub-view-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
