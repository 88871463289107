.TableOfContent-wrapper {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%; // set height in the parent element
  margin: 0;
  padding: 0;

  .ReactVirtualized__List {
    border: 2px solid #DEEEF7;
    border-radius: 20px;
    outline: none;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
    }
  }
}

.TableOfContent-section-element {

  border: 0 solid #000000;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    background-color: rgba(3, 78, 151, 0.08);
  }
  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  > strong {
    margin-left: 1rem;
    margin-right: 0.2rem;
  }
}
