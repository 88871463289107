body {
  height: 100vh;
  min-height: 100vh;
  overflow-x: hidden;
}

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h1 {
  // font-size: 3.75rem; // 60px
  font-size: 3rem;
}

h2 {
  font-size: 3rem; // 48px
}

h3 {
  font-size: 2.125rem; // 34px
}

h4 {
  font-size: 1.75rem; // 28px
}

h5 {
  font-size: 1.5rem; // 24px
}

h6 {
  font-size: 1.25rem; // 20px
}
.custom-h2 {
  font-size: 2.125rem;
}
.custom-h3 {
  font-size: 1rem;
  font-weight: 400;
}

/* custom font classes */
/* use <h1-h6> or <p> for size and custom classes with mixins for font-weight and font-family */

/* main header for every section - use h5 - with <strong> for blue text and */
.main-header,
.main-header-mt-none {
  font-size: 1.5rem;
  @include font-fam($montserrat);
  @include font-weight(500);
  margin-bottom: 60px;
  span {
    @include font-weight(700);
    color: $primary !important;
  }
}

.subtitle-1 {
  font-family: $font-family-sans-serif;
  font-size: 1.25rem;
  //line-height: 1.2rem;
  font-weight: 700;
}

.subtitle-2 {
  font-family: $font-family-sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
}

.subtitle-3 {
  font-family: $montserrat;
  font-size: 0.875rem;
  font-weight: 400;
}

.body-1 {
  font-size: 1.125rem; //18px
}

.body-3 {
  font-size: 0.875rem;
}

.caption {
  font-size: 0.75rem;
}

.opacity-60 {
  opacity: 0.6;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.text-link {
  color: $primary !important;
  cursor: pointer;
  &:visited {
    color: $primary;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline !important;
  }
}

.disabled-link {
  cursor: default;
  color: grey !important;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: grey;
  }
}

/* because WCAG (leave labels hidden but still displayed for blind readers ) */
.hidden-label {
  position: absolute;
  left: -10000000000px;
}

/* wrappers */

.full-wrapper {
  width: 100%;
  max-width: 1950px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-wrapper {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.subpage-margins {
  margin-top: 20px;
  margin-bottom: 90px;
  @include media-breakpoint-up(md) {
    margin-top: 25px;
  }
}

/* navbar */

.navbar {
  font-family: $montserrat;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.02em;
  padding: 0.5rem 0;
}

.navbar-collapse {
  justify-content: flex-end;
}

.navbar-nav {
  text-align: center;
}

a.nav-link {
  color: $dark;
  &.active,
  &:hover {
    color: $primary;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 30px !important;
    padding-right: 0 !important;
  }
}

/* footer */

.footer {
  width: 100%;
  background-color: $dark;
  color: #fff;
}

.footer-content {
  max-width: 1200px;
  margin: auto;
}

.footer-column {
  width: 100%;
  &:first-child {
    margin-top: 10px;
  }
  @include media-breakpoint-up(md) {
    width: auto;
    &:first-child {
      margin-top: 0px;
    }
  }
  margin-bottom: 10px;
  padding-bottom: 20px;
}

.footer-logo-1 {
  margin-top: 20px;
  height: 70px;
}

.footer-logo-2,
.footer-logo-3 {
  margin-top: 20px;
}

.footer-logo-3 {
  @include media-breakpoint-up(md) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.footer-column-box {
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: right;
    margin-left: auto;
    margin-right: 0;
  }
}

button.footer-btn {
  @media (min-width: 1921px) {
    min-width: 230px;
  }
  width: 200px;
}

button.footer-btn:nth-child(1) {
  margin: 50px 0 0 0;
}

.footer-social-icons {
  font-family: $montserrat;
  font-size: 18px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }
  img {
    margin-right: 15px;
    margin-left: 15px;
    @include media-breakpoint-up(md) {
      margin-right: 33px;
      margin-left: 0;
    }
  }
  p {
    margin-bottom: 5px;
  }
  @include media-breakpoint-up(md) {
    margin-top: 55px;
  }
}

.footer-social-findus {
  width: 176px;
  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.brands-container {
  padding: 0px 15px;
}

.brands-container .content-wrapper {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.brand {
  margin: 25px 20px;
  @include media-breakpoint-down(md) {
    width: 17%;
  }
  @include media-breakpoint-down(xs) {
    width: 30%;
  }
  width: 5%;
}

.brand a {
  height: 100%;
  width: 100px;
  display: flex;
  justify-content: flex-start;
  margin: auto;
}

.brand:nth-child(2n) a {
  @include media-breakpoint-down(xs) {
    justify-content: flex-end;
  }
}

.brand a svg {
  width: 80px;
}

.brand svg path:not(.circle-icon),
.brand svg circle {
  filter: url(#constantgrey);
}

.brand:hover,
.brand:active,
.brand:focus {
  cursor: pointer;
  & svg circle,
  svg path {
    filter: none;
  }
}

/* loading */

.lektury-loader {
  width: 100px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -50px;
  background-color: rgba(0, 0, 0, 0.5);
}

/* shadow for images */

.image-shadow-left {
  @include img-shadow-left(30px, 30px, 0);
  @include media-breakpoint-down(md) {
    @include img-shadow-left(18px, 18px, 0);
  }
}

.image-shadow-right {
  @include img-shadow-right(30px, 30px, 0);
  @include media-breakpoint-down(md) {
    @include img-shadow-right(18px, 18px, 0);
  }
}

.image-box {
  max-width: 539px;
  max-height: 514px;
  text-align: center;
}

.image-box img {
  border-radius: 33px;
}

/* header for sections */

section.section-header {
  @include media-breakpoint-down(md) {
    @include header-margin-bottom(27px); //27px will be added to 26px box shadow
  }
  @include media-breakpoint-up(md) {
    @include header-margin-bottom(70px);
  }
}

.section-header {
  overflow: hidden;
  border-radius: 33px;
  padding: 0;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  color: white;
  background: transparent;
  background-image: url('../images/book-parallax.jpg');
  height: 174px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include img-shadow-left(34px, 26px, 0);
  @include media-breakpoint-down(sm) {
    width: 100%;
    @include img-shadow-left(0, 20px, 0);
  }
  @include media-breakpoint-up(md) {
    @include img-shadow-left(34px, 26px, 0);
  }
}

.section-header .section-search {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    width: 90%;
  }
  @include media-breakpoint-up(md) {
    width: 70%;
  }
}

.section-search input {
  width: 100%;
  border: none;
  height: 60px;
  font-size: 18px;
  border-radius: 30px;
  padding-left: 30px;
  background: #fff;
  filter: drop-shadow(0px 0px 6px rgba(35, 38, 79, 0.2));
  @include media-breakpoint-down(sm) {
    padding-left: 20px;
    font-size: 12px;
  }
  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

.section-search input::placeholder {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: black;
  opacity: 0.85;
}

.section-search .btn-primary {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 5px;
  border: 1px solid $secondary;
  color: white;
  border-radius: 25px;
  min-width: 0;
  background: $primary;
  padding: 0px 30px 0px 30px;
  box-shadow: 0px 3px 6px rgba(3, 78, 151, 0.15);
  @include media-breakpoint-down(sm) {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    padding: 0;
  }
  @include media-breakpoint-up(sm) {
    border-radius: 25px;
    width: 150px;
    height: 50px;
    padding: 0;
  }
}

.section-search .btn-primary:hover {
  background-color: $secondary;
  border: 1px solid $secondary;
  box-shadow: 0 6px 6px rgba(3, 78, 151, 0.15);
}

.i-text {
  font-family: 'Roboto', sans-serif;
  @include media-breakpoint-down(sm) {
    display: none;
  }
  @include media-breakpoint-up(sm) {
    display: inline-block;
  }
}

/* agreement for forms */

.agreement {
  width: 100%;
  margin-top: 70px;
}

.agreement-txt {
  font-size: 18px;
  font-family: $font-family-sans-serif;
}

.agreement ul {
  padding-left: 11px;
}

.agreement ul > li {
  list-style-type: none;
  margin: 20px 0 20px 0;
}

.agreement ul > li:before {
  content: '\2022';
  font-size: 60px;
  padding-right: 20px;
  vertical-align: middle;
  line-height: 5px;
  color: $primary;
}

.home-search input:focus,
.section-search input:focus {
  outline: none;
  box-shadow: 0px 0px 10px rgba(3, 78, 151, 0.8);
}

.navbar-toggler:focus {
  outline: none;
}

.nav-link:focus {
  outline: none;
}
