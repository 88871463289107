.home-content-section .main-header {
  text-align: left;
}

.program-card {
  margin: 0 20px 30px 20px;
  font-family: $font-family-sans-serif;
  @include media-breakpoint-down(lg) {
    margin-top: 30px;
  }
}

.program-card h4 {
  color: $primary;
  font-weight: bold;
  height: 50px;
  width: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.program-card .btn-primary {
  margin-top: 10px;
  width: 100%;
  @include media-breakpoint-down(lg) {
    width: 70%;
  }
}

.program-card h5 {
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.program-book-img-bg {
  width: 95px;
  height: 95px;
  position: absolute;
  top: 50%;
  left: -10%;
  transform: translate(0%, -80%);
  z-index: -1;
  transition: 0.6s;
  @include media-breakpoint-down(lg) {
    top: 50%;
    left: 15%;
    height: 120px;
    width: 120px;
    transform: translate(0%, -70%);
  }
  @include media-breakpoint-down(md) {
    left: 10%;
    transform: translate(15%, -65%);
  }
  @include media-breakpoint-down(sm) {
    left: 25%;
    transform: translate(40%, -70%);
  }
  @include media-breakpoint-down(xs) {
    transform: translate(-10%, -70%);
  }
}

.program-book-img-bg-hover {
  width: 95px;
  height: 95px;
  position: absolute;
  z-index: -1;
  transition: 0.6s;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  @include media-breakpoint-down(lg) {
    height: 120px;
    width: 120px;
  }
}

.program-card-image {
  position: relative;
  margin: auto;
}

.program-book-img {
  width: 160px;
  height: 135px;
}

.home-section-title {
  margin-bottom: 100px;
}

.home-section-title {
  margin-bottom: 100px;
}

.full-width-banner {
  width: 100%;
  height: 478px;
  position: relative;
  margin-top: 185px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  text-align: center;
}

.full-width-banner:before {
  content: '';
  max-width: 357px;
  height: 80px;
  background-image: url('../images/bottom_banner_waves.svg');
  background-position: center;
  display: block;
  margin: auto;
  position: relative;
  top: -80px;
}

.full-width-banner-body {
  width: 90%;
  margin: auto;
}

.full-width-banner h2,
h4 {
  font-family: $montserrat;
}

.full-width-banner h2 {
  margin-bottom: 40px;
  @include media-breakpoint-down(sm) {
    font-size: 28px;
  }
}

.full-width-banner h4 {
  margin-bottom: 50px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

.full-width-banner p {
  font-size: 16px;
  margin-bottom: 40px;
}

.full-width-banner .btn-primary {
  padding: 0 34px 0 34px;
}

#mobile-app-banner {
  background-image: url('../images/mobile_app_banner.jpg');
  @include media-breakpoint-down(sm) {
    background-image: url('../images/mobile_app_banner_small.jpg');
  }
}

#add-project-banner {
  background-image: url('../images/add_project_banner.jpg');
  margin-top: 150px;
  @include media-breakpoint-down(sm) {
    background-image: url('../images/add_project_banner_small.jpg');
  }
}
