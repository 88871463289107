h5.section-title {
  color: #034e97;
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.31px;
}

.youtube-player-wrapper {
  @include img-shadow-left(26px, 26px, 0);
  padding: 0;
  overflow: hidden;
}

.youtube-player-preview {
  @include img-shadow-left(0, 0, 0);
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 33px;
  //padding: 0;
  overflow: hidden;
}

%play-overlay {
  cursor: pointer;

  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    background-position: center;
    background-repeat: no-repeat;

    background-image: url('../images/icons/youtube_play.svg');
    background-color: rgba(35, 38, 79, 0.5);
  }
}

.bg-black {
  background-color: black;
}

.play-layer {
  @extend %play-overlay;
}

.project-pages-img {
  width: 100%;
  padding-top: 100%;
  border-radius: 33px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.project-download-wrapper {
  height: 60px;
}
