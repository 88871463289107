.checkAlso-header {
  @include font-fam($montserrat);
  @include font-weight(500);
  border-bottom: 2px solid $lightblue;
  margin-bottom: 0;
  margin-top: 50px;
}

.checkAlso-projects-header {
  margin-top: 30px;
  margin-bottom: 36px;
}

// question - czy zdjęcia projektów będą kwadratowe?
.checkAlso-project-img {
  @include img-shadow-right(20px, 22px, 100%);
}

.checkAlso-page-wrapper {
  cursor: pointer;
}

.checkAlso-pages-header {
  margin-top: 30px;
  margin-bottom: 50px;
}

// question - czy zdjęcia projektów będą kwadratowe?
.checkAlso-pages-img-wrapper {
  width: 100%;
  max-width: 150px;
  margin-right: 0;
  margin-bottom: 32px;
  @include media-breakpoint-up(sm) {
    margin-right: 45px;
  }
}
.checkAlso-pages-img {
  @include img-shadow-left(10px, 10px, 100%);
}

.checkAlso-books-header {
  margin-top: 76px;
  margin-bottom: 50px;
  @include media-breakpoint-down(md) {
    margin-top: 50px;
    margin-bottom: 40px;
  }
}

.checkAlso-tags-header {
  margin-top: 50px;
  margin-bottom: 30px;
}

.checkAlso-tags-button {
  @extend .btn-secondary;
  border-radius: 5px;
  padding: 8px 12px;
  margin-right: 8px;
  font-size: 14px;
  min-width: auto;
}
