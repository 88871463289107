/* buttons */
// for all buttons use two classNames: btn-primary/btn-secondary + btn-txt/btn-ico

%btn {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 25px;
  height: 50px;
  text-align: center;
  min-width: 200px;
  &:focus,
  &:active:focus {
    outline: none !important;
    box-shadow: 0 0 0 0.1rem rgba(43, 146, 201, 0.5) !important;
  }
}

.btn-primary {
  @extend %btn;
  background-color: $primary;
  color: #fff;
  border: 1px solid $primary;
  box-shadow: 0 3px 6px rgba(3, 78, 151, 0.15);
  transition: all 0.3s;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $secondary;
    border: 1px solid $secondary;
    box-shadow: 0 6px 6px rgba(3, 78, 151, 0.15);
  }
}

.btn-secondary {
  @extend %btn;
  background-color: transparent;
  color: $primary;
  border: 1px solid $primary;
  transition: all 0.3s;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $lightblue;
    color: $primary;
    border: 1px solid $secondary;
  }
}

.btn-ico {
  padding: 0 55px;
  background-repeat: no-repeat;
  background-position: 20px center;
}

.btn-txt {
  padding: 0 20px;
}

.btn-search {
  @extend .btn-primary;
  @extend .btn-ico;
  min-width: auto;
  @include media-breakpoint-down(md) {
    padding: 0;
    width: 50px !important;
    background-position: center;
    span {
      display: none;
    }
  }
}

.btn-primary[disabled] {
  background-color: $color_form_error;
  color: #ffffff;
  border-color: $color_form_error;
  cursor: not-allowed;
  box-shadow: none;
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background-color: $color_form_error;
    border-color: $color_form_error;
    box-shadow: none;
  }
}
