section.home-header {
  @include header-margin-bottom(40px); //40px will be added to 26px shadow
}

.home-header {
  overflow: hidden;
  border-radius: 33px;
  position: relative;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: white;
  height: 478px;
  width: 100%;
  @include media-breakpoint-down(sm) {
    @include img-shadow-left(0, 26px, 0);
    height: 357px;
  }
  @include media-breakpoint-up(sm) {
    @include img-shadow-left(0, 26px, 0);
    height: 357px;
  }
  @include media-breakpoint-up(md) {
    @include img-shadow-left(34px, 26px, 0);
  }
  @include media-breakpoint-up(xl) {
    height: 478px;
  }
  margin-top: 25px;
}

.home-header-background {
  position: absolute;
  width: 100%;
  height: 150%;
  background-image: url('../images/book-parallax.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-clip: border-box;
  transform: translateY(-50px);
  @include media-breakpoint-up(lg) {
    transform: translateY(-100px);
  }
}

.home-header-text,
.home-search {
  width: 70%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 150px;
}

.home-header-text {
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding-left: 10px;
    margin-top: 110px;
  }
  @include media-breakpoint-up(sm) {
    padding-left: 15px;
    margin-top: 100px;
  }
  @include media-breakpoint-up(lg) {
    width: 80%;
    padding-left: 60px;
    margin-top: 130px;
  }
  @include media-breakpoint-up(xl) {
    width: 70%;
    padding: 0;
    margin-top: 120px;
  }
}

.home-header-text h2 {
  font-weight: 400;
  @include media-breakpoint-down(sm) {
    font-size: 48px;
  }
  @include media-breakpoint-up(sm) {
    font-weight: 400;
    font-size: 54px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 3rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3rem;
  }
}

.home-header-text h3 {
  font-weight: 300;
  @include media-breakpoint-down(sm) {
    font-weight: 400;
    font-size: 14px;
  }
  @include media-breakpoint-up(sm) {
    font-weight: 300;
    font-size: 18px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2rem;
  }
}

.home-search {
  margin-top: 300px;
  @include media-breakpoint-down(sm) {
    width: 90%;
    margin-top: 230px;
  }
  @include media-breakpoint-up(sm) {
    width: 90%;
    margin-top: 230px;
  }
  @include media-breakpoint-up(md) {
    width: 70%;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 250px;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 270px;
  }
}

.home-search input {
  width: 100%;
  border: none;
  height: 60px;
  font-size: 18px;
  border-radius: 30px;
  padding-left: 30px;
  background: #fff;
  filter: drop-shadow(0px 0px 6px rgba(35, 38, 79, 0.2));
  @include media-breakpoint-down(sm) {
    padding-left: 20px;
    font-size: 12px;
  }
  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 18px;
  }
}

.home-search input::placeholder {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: black;
  opacity: 0.85;
}

.home-search .btn-primary {
  position: absolute;
  right: 0;
  border: 1px solid $secondary;
  color: white;
  border-radius: 25px;
  min-width: 0;
  background: $primary;
  padding: 0px 30px 0px 30px;
  box-shadow: 0px 3px 6px rgba(3, 78, 151, 0.15);
  @include media-breakpoint-down(sm) {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    padding: 0;
    margin: 5px 5px 0 0;
  }
  @include media-breakpoint-up(sm) {
    border-radius: 25px;
    width: 150px;
    height: 50px;
    margin: 5px 5px 0 0;
  }
}

.home-search .btn-primary:hover {
  background-color: $secondary;
  border: 1px solid $secondary;
  box-shadow: 0 6px 6px rgba(3, 78, 151, 0.15);
}

.program-card .btn-primary {
  min-width: 0;
}
