.readings-input-label {
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: 500;
  padding: 0 10px;
  color: inherit;
  z-index: 1;
  transition: 0.2s ease all;
  pointer-events: none;
}

.reading-phone-input .readings-input-label {
  left: 39px;
}

.readings-input-field {
  box-shadow: none !important;
  &:focus {
    border: 2px solid $primary !important;
  }
  &.is-invalid {
    border: 2px solid #dc3545 !important;
  }
}

/* active state */
.readings-input-field:focus ~ .readings-input-label,
.readings-input-field:valid ~ .readings-input-label {
  top: 0px;
  left: 7px;
  font-size: 12px;
  background: #fff;
}

.readings-input-field[type='email']:not([value='']) ~ .readings-input-label {
  top: 0px;
  left: 7px;
  font-size: 12px;
  background: #fff;
}

.readings-input-wrapper {
  position: relative;
  color: $color_form_ok;
  border-color: $color_form_ok;
  transition: all 0.015s linear;

  .readings-input-field,
  .readings-select__control {
    border: 1px solid $color_form_ok;
    height: 51px;
    border-radius: 4px;
  }
  textarea.readings-input-field {
    height: auto;
    & ~ label {
      top: 30px;
    }
    &:focus {
      padding-bottom: 4px;
    }
  }
  .readings-select__control:hover {
    border-color: $color_form_active;
    box-shadow: 0 0 0 1px lighten($color_form_active, 50%);
  }
  .readings-input-field,
  .readings-select__single-value {
    color: #000;
  }
  .readings-select__value-container {
    padding: 2px 12px;
  }
  .readings-select__menu {
    margin-top: 1px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
  }
  .readings-select__indicator-separator {
    display: none;
  }
  &.focused .readings-input-label {
    color: $color_form_active;
  }
  &.focused .readings-input-field {
    border-color: $primary !important;
    border-style: solid;
  }
  &.invalid .readings-input-label {
    color: $color_form_error;
  }
  &.invalid .readings-input-field,
  .readings-input-field.is-invalid {
    border-color: $color_form_error;
    border-style: solid;
    box-shadow: 0 0 0 1px lighten($color_form_error, 50%);
  }
}

.readings-checkbox {
  transition: all 0.015s linear;
  .custom-control-label::before {
    border: 1px solid $color_form_ok;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $green;
    background-color: $green;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    border-color: $green;
    box-shadow: 0 0 0 0.15rem rgba(0, 209, 181, 0.25);
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url('../images/icons/tick.svg');
    transform: scale(1.3, 1.3);
  }
}

.reading-form-group {
  position: relative;
}

.reading-phone-prepend {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  .input-group-text {
    color: #000;
    background-color: transparent;
    border: none;
    width: 51px;
  }
}

.reading-phone-input {
  width: 100%;
  .readings-input-field {
    padding-left: 51px;
  }
}

.readings-field-error-msg {
  color: $color_form_error;
  font-size: 0.85rem;
  min-height: 1.25rem;
  margin-top: 6px;
}

.readings-radio-checkbox {
  .custom-control-label::before {
    height: 16px;
    width: 16px;
    border: 1px solid $color_form_ok;
    border-radius: 0.25rem;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $green;
    background-color: $green;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url('../images/icons/tick.svg');
    transform: scale(1.3, 1.3);
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.15rem rgba(0, 209, 181, 0.25);
  }
}

.readings-form-greyed {
  color: $color_form_ok;
}

.form-captcha-error iframe {
  border: 1px solid $color_form_error;
  box-shadow: 0 0 0 1px lighten($color_form_error, 50%);
  border-radius: 4px;
}

.readings-alert-success {
  color: $primary;
  background-color: rgba(3, 78, 151, 0.08);
  border-color: $primary;
}
.readings-alert-error {
  color: $color_form_error;
  background-color: rgba(176, 0, 32, 0.08);
  border-color: $color_form_error;
}
