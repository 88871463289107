/* bootstrap override variables defaults here */

/* font */

$font-family-sans-serif: 'Roboto', sans-serif;
$montserrat: 'Montserrat', sans-serif;

/* colors */
$primary: #034e97;
$secondary: #067fbf;
$dark: #1d2636;
$green: #00d1b5;
$purple: #23264f;
$lightblue: #deeef7;

$color_form_ok: rgba(29, 38, 54, 0.6); //dark color 60% opacity
$color_form_active: $primary;
$color_form_error: #b00020;
$color_select_hover: rgba(3, 78, 151, 0.08); //primary color 8% opacity

$body-color: #000;

/* mixins and placeholders */
@mixin font-fam($font-fam) {
  font-family: $font-fam;
}
@mixin font-weight($font-weight) {
  font-weight: $font-weight;
}
%img-shadow {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 33px;
}

@mixin img-shadow-left($x, $y, $paddingRatio) {
  @extend %img-shadow;
  width: calc(100% - #{$x});
  padding-top: calc(#{$paddingRatio} - #{$x});
  box-shadow: -$x $y 0 0 $lightblue;
  margin-bottom: $y;
  margin-left: $x;
}

@mixin img-shadow-right($x, $y, $paddingRatio) {
  @extend %img-shadow;
  width: calc(100% - #{$x});
  padding-top: calc(#{$paddingRatio} - #{$x});
  box-shadow: $x $y 0 0 $lightblue;
  margin-bottom: $y;
  margin-right: $x;
}
@mixin header-margin-bottom($additional) {
  margin-bottom: calc(#{$additional + 26px});
}
