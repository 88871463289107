.reading-pagination {
  justify-content: center;
  margin-top: 70px;
}

.navlink,
.reading-pagination-break,
.reading-page-item {
  cursor: pointer;
}
.navlink {
  a {
    color: $dark;
  }
  span {
    color: $primary;
    display: none;
    @include media-breakpoint-up(md) {
      display: inline;
    }
  }
  img {
    vertical-align: text-top;
  }
  &.navlink-previous {
    margin-right: 18px;
    span {
      margin-left: 10px;
    }
  }
  &.navlink-next {
    margin-left: 18px;
    span {
      margin-right: 10px;
    }
  }
}
.navlink-disabled {
  cursor: default;
  span {
    color: #a0a0a0;
  }
}

.reading-page-item {
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 700;
  font-size: 18px;
  .reading-page-link {
    color: $dark;
  }
  &.active .reading-page-link {
    color: $primary;
  }
}

// styling for the dots
.reading-pagination-break {
}
