/* custom file inputs */

.readings-file-label {
  @extend .btn-secondary;
  @extend .btn-ico;
  background-image: url('../images/icons/icon_attachment.svg');
  cursor: pointer;
  width: 230px;
  // this is a hack to center the span vertically:
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  span {
    vertical-align: middle;
    display: inline-block;
  }
}

.readings-file-input {
  display: none;
}

.file-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
