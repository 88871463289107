.add-project-intro {
  color: $primary;
  margin-bottom: 12px;
}

.add-project-paragraph {
  color: $dark;
  span {
    color: $primary;
  }
}
.add-project-download-container {
  width: 100%;
}
.add-project-download-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0 solid #000000;
  //padding: 0.5rem 0.75rem;
  cursor: pointer;

  &:hover {
    .add-project-download-button {
      background-image: url('../images/icons/remove_attachment.svg');
    }
    background-color: rgba(3, 78, 151, 0.08);
  }
  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.add-project-download-text {
  width: 80%;
}
.add-project-download-button {
  width: 10px;
  height: 10px;
  background-color: transparent;
  background-size: 100% 100%;
  border: 0;
  cursor: pointer;
}
