.not-found-page {
  margin-top: 75px;
}

.not-found-page h2 {
  font-family: $montserrat;
  @include media-breakpoint-down(md) {
    font-size: 32px;
  }
}

.not-found-page img {
  @include media-breakpoint-down(xs) {
    width: 80%;
    margin-top: 60px;
  }
}

.not-found-page h6 {
  font-size: 18px;
  font-weight: lighter;
}

.not-found-page .btn-primary {
  min-width: 260px;
  margin-top: 70px;
}

.not-found-page [class*='col-'] {
  display: flex;
  align-items: center;
}
