.about {
  width: 100%;
  margin-top: 100px;
}

.about-txt {
  margin-top: 30px;
  font-family: $font-family-sans-serif;
  font-weight: 300;
}

.about-txt-bold {
  color: $primary;
  font-weight: bold;
}

.about-txt-regular {
  color: $secondary;
}

.about .main-header {
  margin-top: 0;
}

.about-books-image {
  background-image: url('../images/about_books.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 33px;

  @include media-breakpoint-down(md) {
    @include img-shadow-left(18px, 18px, 100%);
    margin-bottom: 50px;
  }
  @include img-shadow-left(30px, 30px, 100%);
}
