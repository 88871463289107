.accordion-arrow-icon {
  background-image: url('../images/icons/accordion_arrow.svg');
  background-color: transparent;
  background-size: 100% 100%;
  border: 0;
  width: 18px;

  &.closed {
    transform: rotate(180deg);
  }
}

.card.custom-accordion {
  border: none;
  background-color: transparent;
}

.custom-accordion.card-header {
  padding-left: 0;
  background-color: transparent;
  border-bottom-width: 2px;
  border-radius: 2px;
  border-bottom-color: #deeef7;
}

a.custom-accordion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

h5.custom-accordion {
  color: #034e97;
  font-family: Montserrat, serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.31px;
}
