.mp3-react-player {
  position: absolute;
  height: 0;
  width: 0;
}

.player-section {
  @include media-breakpoint-up(md) {
    margin-top: 50px;
  }
}

.mp3-wrapper {
  width: 100%;
  min-height: 275px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include media-breakpoint-down(md) {
    margin: auto;
  }

  button {
    outline: none !important;
  }
}

.custom-column {
  text-align: left;
  @include media-breakpoint-up(md) {
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 35px;
  }
  max-width: unset;
}

.mp3-top-panel {
  display: flex;
  justify-content: space-between;
  max-height: 20px;
  @media (min-width: 3860px) {
    max-height: 17px;
  }
}

.mp3-middle-panel {
  display: flex;
  flex-direction: row;
  min-height: 40px;
  padding: 5px 0;
  border-radius: 30px;
  background-color: #deeef7;
  margin-top: 25px;
}

.mp3-bottom-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 20px;
  margin-right: 3px;
  margin-left: 3px;
  .ReactVirtualized__List {
    outline: none;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
    }
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
  }
}

.mp3-download {
  display: flex;
  flex-direction: column;
  height: 100%; // set height in the parent element
  margin-top: 20px;
  margin-right: 3px;
  margin-left: 3px;
  .ReactVirtualized__List {
    outline: none;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
    }
  }
}
.mp3-action-btns-group {
  display: flex;
  justify-content: space-between;
  width: 25%;
}

%mp3-btn {
  background-color: transparent;
  background-size: 100% 100%;
  border: 0;
  cursor: pointer;
}

.mp3-btn-previous-song {
  @extend %mp3-btn;
  width: 9%;
  background-image: url('../images/icons/player_previous.svg');
  &:hover {
    background-image: url('../images/icons/player_previous_hover.svg');
  }

  &:disabled {
    background-image: url('../images/icons/player_previous_off.svg');
  }
}

.mp3-btn-back-seek {
  @extend %mp3-btn;
  background-image: url('../images/icons/player_rewind.svg');
  width: 18%;
  &:hover {
    background-image: url('../images/icons/player_rewind_hover.svg');
  }
  &:disabled {
    background-image: url('../images/icons/player_rewind_off.svg');
  }
}

.mp3-btn-forward-seek {
  @extend %mp3-btn;
  background-image: url('../images/icons/player_ffw.svg');
  width: 18%;
  &:hover {
    background-image: url('../images/icons/player_ffw_hover.svg');
  }
  &:disabled {
    background-image: url('../images/icons/player_ffw_off.svg');
  }
}

.mp3-btn-next-song {
  @extend %mp3-btn;
  width: 9%;
  background-image: url('../images/icons/player_next.svg');
  &:hover {
    background-image: url('../images/icons/player_next_hover.svg');
  }
  &:disabled {
    background-image: url('../images/icons/player_next_off.svg');
  }
}

.mp3-playbackrate-group {
  display: flex;
  justify-content: space-between;
  width: 30%;

  .playbackrate {
    margin-top: auto;
    margin-bottom: auto;
    width: 65%;
  }
  .mp3-speed {
    @extend %mp3-btn;
    background-image: url('../images/icons/player_speed.svg');
    width: 15%;
    margin-right: 7px;
    cursor: default;
    /* &:hover {
      background-image: url('../images/icons/player_speed_hover.svg');
    } */
  }
}

.mp3-btn-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5px;
  margin-right: 5px;
}

.mp3-duration {
  text-align: center;
  font-size: 12px;
  margin-left: 10px;
  @media (min-device-width: 1024px) {
    font-size: small;
  }
}

.mp3-btn-box button {
  padding: 0;
}

.mp3-btn-play,
.mp3-btn-pause {
  height: 30px;
  width: 30px;
}

.mp3-btn-pause {
  @extend %mp3-btn;
  background-image: url('../images/icons/player_pause.svg');
  &:hover {
    background-image: url('../images/icons/player_pause_hover.svg');
  }
}
.mp3-btn-play {
  @extend %mp3-btn;
  background-image: url('../images/icons/player_play.svg');
  background-size: cover;
  background-repeat: no-repeat;
  &:hover {
    background-image: url('../images/icons/player_play_hover.svg');
  }
}

.mp3-seek {
  display: flex;
  align-items: center;
  width: 50%;
  margin: auto auto;
  position: relative;
  @media (max-width: 991.98px) and (min-device-width: 1024px) {
    width: 40%;
  }
  @media (max-width: 575.98px) and (min-device-width: 1024px) {
    width: 25%;
  }
}

.mp3-seek-input {
  z-index: 91;
  width: 100%;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
}

.mp3-loaded-progress {
  $custom-range-track-height: 0.0625rem;
  position: absolute;
  z-index: 90;
  height: $custom-range-track-height;
  margin-top: auto;
  margin-bottom: auto;
  pointer-events: none;
}

.mp3-sound-group {
  display: none;
  flex-direction: row;
  //justify-content: space-between;
  justify-content: space-evenly;
  width: 5%;
  height: 80%;
  margin: auto 10px auto 5px;
  transition: 0.2s ease-in-out;
  justify-content: flex-end;
  &.sound-control-open {
    width: 20%;
    height: 80%;
    justify-content: center;
    background-color: rgba(3, 78, 151, 0.08);
    border-radius: 30px;
    background-size: 90%;
    padding-left: 8px;
  }
  .mp3-sound-icon {
    @extend %mp3-btn;
    background-image: url('../images/icons/player_sound.svg');
    right: 0;
    margin: auto 0;
    height: 15px;
    width: 32px;
  }
  @media (min-device-width: 1024px) {
    display: flex;
  }
}

.mp3-sound-range {
  width: 65%;
  margin-top: auto;
  margin-bottom: auto;
}

.mp3-list-item {
  border: 0 solid #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  cursor: pointer;

  &.selected {
    .mp3-list-item-play-icon {
      @extend %mp3-btn;
      background-image: url('../images/icons/play-button.svg');
    }
  }
  &:hover {
    background-color: rgba(3, 78, 151, 0.08);
  }
  .mp3-list-item-play-icon {
    width: 10px;
    height: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.mp3-custom-range {
  $custom-range-track-height: 0.0625rem;
  $custom-range-track-bg: transparentize(black, 0.5);
  height: calc(#{$custom-range-thumb-height} + #{$custom-range-thumb-focus-box-shadow-width * 2});
  padding: 0; // Need to reset padding
  background-color: transparent;
  appearance: none;

  &:focus {
    outline: none;

    // Pseudo-elements must be split across multiple rulesets to have an effect.
    // No box-shadow() mixin for focus accessibility.
    &::-webkit-slider-thumb {
      box-shadow: $custom-range-thumb-focus-box-shadow;
    }
    &::-moz-range-thumb {
      box-shadow: $custom-range-thumb-focus-box-shadow;
    }
    &::-ms-thumb {
      box-shadow: $custom-range-thumb-focus-box-shadow;
    }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    width: $custom-range-thumb-width;
    height: $custom-range-thumb-height;
    margin-top: ($custom-range-track-height - $custom-range-thumb-height) / 2; // Webkit specific
    @include gradient-bg($custom-range-thumb-bg);
    border: $custom-range-thumb-border;
    @include border-radius($custom-range-thumb-border-radius);
    @include box-shadow($custom-range-thumb-box-shadow);
    @include transition($custom-forms-transition);
    appearance: none;

    &:active {
      @include gradient-bg($custom-range-thumb-active-bg);
    }
  }

  &::-webkit-slider-runnable-track {
    width: $custom-range-track-width;
    height: $custom-range-track-height;
    color: transparent; // Why?
    cursor: $custom-range-track-cursor;
    background-color: $custom-range-track-bg;
    border-color: transparent;
    @include border-radius($custom-range-track-border-radius);
    @include box-shadow($custom-range-track-box-shadow);
  }

  &::-moz-range-thumb {
    width: $custom-range-thumb-width;
    height: $custom-range-thumb-height;
    @include gradient-bg($custom-range-thumb-bg);
    border: $custom-range-thumb-border;
    @include border-radius($custom-range-thumb-border-radius);
    @include box-shadow($custom-range-thumb-box-shadow);
    @include transition($custom-forms-transition);
    appearance: none;

    &:active {
      @include gradient-bg($custom-range-thumb-active-bg);
    }
  }

  &::-moz-range-track {
    width: $custom-range-track-width;
    height: $custom-range-track-height;
    color: transparent;
    cursor: $custom-range-track-cursor;
    background-color: $custom-range-track-bg;
    border-color: transparent; // Firefox specific?
    @include border-radius($custom-range-track-border-radius);
    @include box-shadow($custom-range-track-box-shadow);
  }

  &::-ms-thumb {
    width: $custom-range-thumb-width;
    height: $custom-range-thumb-height;
    margin-top: 0; // Edge specific
    margin-right: $custom-range-thumb-focus-box-shadow-width; // Workaround that overflowed box-shadow is hidden.
    margin-left: $custom-range-thumb-focus-box-shadow-width; // Workaround that overflowed box-shadow is hidden.
    @include gradient-bg($custom-range-thumb-bg);
    border: $custom-range-thumb-border;
    @include border-radius($custom-range-thumb-border-radius);
    @include box-shadow($custom-range-thumb-box-shadow);
    @include transition($custom-forms-transition);
    appearance: none;

    &:active {
      @include gradient-bg($custom-range-thumb-active-bg);
    }
  }

  &::-ms-track {
    width: $custom-range-track-width;
    height: $custom-range-track-height;
    color: transparent;
    cursor: $custom-range-track-cursor;
    background-color: transparent;
    border-color: transparent;
    border-width: $custom-range-thumb-height / 2;
    @include box-shadow($custom-range-track-box-shadow);
  }

  &::-ms-fill-lower {
    background-color: $custom-range-track-bg;
    @include border-radius($custom-range-track-border-radius);
  }

  &::-ms-fill-upper {
    margin-right: 15px; // arbitrary?
    background-color: $custom-range-track-bg;
    @include border-radius($custom-range-track-border-radius);
  }

  &:disabled {
    &::-webkit-slider-thumb {
      background-color: $custom-range-thumb-disabled-bg;
    }

    &::-webkit-slider-runnable-track {
      cursor: default;
    }

    &::-moz-range-thumb {
      background-color: $custom-range-thumb-disabled-bg;
    }

    &::-moz-range-track {
      cursor: default;
    }

    &::-ms-thumb {
      background-color: $custom-range-thumb-disabled-bg;
    }
  }
}
