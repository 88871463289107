a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  .book-wrapper {
    height: 100%;
    border: 1px solid $lightblue;
    border-radius: 20px;
    box-shadow: 0px 8px 0px $lightblue;
    position: relative;
    top: 0;
    transition: 0.2s ease-out;
    display: flex;
    flex-flow: column nowrap;

    &:hover {
      box-shadow: 0px 20px 10px rgba(3, 78, 151, 0.15);
      top: -10px;
    }

    .book-details {
      padding: 0 20px;
      display: flex;
      flex-grow: 1;
      flex-flow: column nowrap;
      p.subtitle-2 {
        color: $dark;
        padding-top: 30px;
      }
      p.author-name {
        color: $primary;
        text-align: center;
        margin-top: auto;
      }
    }

    .book-cover-wrapper {
      padding: 35px 55px 0;

      .cover-aspect-ratio-box {
        height: 0;
        overflow: hidden;
        padding-top: 138%;
        position: relative;
        box-shadow: 7px 8px 18px rgba(35, 38, 79, 0.31);
      }

      .cover-aspect-ratio-box-inside {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .book-cover {
        object-fit: cover;
        width: 100%;
      }
    }
  }
}

.cover-big-wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  &:after {
    content: '';
    background-color: $lightblue;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .cover-big-img {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 72%;
    transform: translate(-50%, 0);
    z-index: 10;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 7px 8px 18px rgba(35, 38, 79, 0.31);
  }
}

/* use h6 */
.cover-title {
  @include font-fam($montserrat);
  @include font-weight(500);
}

/* use p */
.cover-author {
  color: $primary;
}

/* styles for one book page */

.reading-about {
  margin-bottom: 50px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 65px;
  }
}

.reading-buttons-wrapper {
  margin: 3rem 0 50px;
  @include media-breakpoint-up(sm) {
    margin: 3rem 20% 1.5rem;
  }
}

.reading-book-btn {
  @include media-breakpoint-up(md) {
    font-size: 0.8125rem;
    padding: 0 30px;
    background-size: auto 15px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1rem;
    padding: 0 55px;
    background-size: auto;
  }
}

.reading-book-about p,
.reading-book-additional p {
  @extend .body-1;
}

.reading-book-about {
  margin-bottom: 25px;
}

.reading-download-wrapper {
  height: 160px;
}
